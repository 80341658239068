import React, { Component, useState, useEffect, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import jstz from 'jstz';
import Color from 'color';

import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import {colors} from 'style';
import Utils from 'utils/Utils.js';
import moment from 'moment'
import { Tab, Popup, Menu } from 'semantic-ui-react';

import Dashboard from './Dashboard.js';

const platformChartOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                stacked: true,
                ticks: {
                    display:false
                },
                gridLines: {
                    display:false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                    display:false
                },
                gridLines: {
                    drawBorder: false,
                    display:false
                }   
            }
        ]
    },
    reponsive: true,
    maintainAspectRatio: false
};

const graphOptions = {
    tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                return Number(tooltipItem.yLabel).toFixed(2);
            }
        }
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                gridLines: {
                    display:false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
                gridLines: {
                }   
            }
        ]
    },
    responsive: true,
    maintainAspectRatio: false
}

const EmployeesGraph  = 0;
const DevicesGraph    = 1;
const EngagementGraph = 2;

const DashboardContainer = ({t, dispatch}) => {
    const [busy, setBusy] = useState(false)
    const [selectedGraph, setSelectedGraph] = useState(EngagementGraph)
    const [teams, setTeams] = useState([])
    const [users, setUsers] = useState([])
    const [rewards, setRewards] = useState([])
    const [participation, setParticipation] = useState([])
    const [devices, setDevices] = useState([])
    const [categories, setCategories] = useState([])
    const [company, setCompany] = useState({})
    const [registrations, setRegistrations] = useState({})
    const [platform, setPlatform] = useState()
    const [summary, setSummary] = useState()
    
    const downloadLink = createRef()

    const loadData = async () => {
        //Simplify using util?
        var endDate = new Date()
        endDate.setHours(0)
        endDate.setMinutes(0)
        endDate.setSeconds(0)
        endDate.setMilliseconds(0)
        endDate.setDate(endDate.getDate() + 1);
        var startDate = new Date(endDate)
        startDate.setDate(startDate.getDate() - 30)

        setBusy(true)

        let result = await DatabaseService.getDashboard({
            rewards : true,
            goals : true,
            teams : true,
            leaderboard : true,
            engagement : true,
            platforms : true,
            timeZone : (jstz.determine()).name()
        })

        setCategories(result.categories.result)
        setTeams(result.teams.result)
        setUsers(result.leaderboard.result)
        setRewards(result.rewards.result)
        setParticipation(result.participation)
        setDevices(result.devices)
        setCompany(result.company)
        setRegistrations(result.registrations)
        setPlatform(result.platform)
        setSummary(result.summary)
        setBusy(false)
    }

    useEffect(() => {
        dispatch(setSidebarEnable(true))
        loadData()
    }, [])

    const getPlatformEngagement = () => {
        if(!platform || !registrations) {
            return {}
        }
        var iosCount = Math.round(platform.ios / registrations.total * 100);
        var androidCount = Math.round(platform.android / registrations.total * 100);

        var platEngagementData = [
            [iosCount], [androidCount]
        ];

        var result = {
            iosEngagement: (isFinite(iosCount) ? iosCount + "%" : "-- %"),
            androidEngagement: (isFinite(androidCount) ? androidCount + "%" : "-- %"),
            chartData: {
              labels: ['iOS', 'Android'],
              datasets: [
                {
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  borderWidth: 1,
                  borderRadius: 5,
                  hoverBackgroundColor: Color(colors.themeBlue).alpha(0.50).string(),
                  hoverBorderColor: Color(colors.themeBlue).alpha(0.75).string(),
                  data: [iosCount,0] 
                },
                {
                  backgroundColor: colors.themeTeal,
                  borderColor: colors.themeTeal,
                  borderWidth: 1,
                  hoverBackgroundColor: Color(colors.themeTeal).alpha(0.50).string(),
                  hoverBorderColor: Color(colors.themeTeal).alpha(0.75).string(),
                  data: [0,androidCount]
                }
              ]
            }
        };
        return result;
    }


    const getGraphData = () => {
        var result = {};
        if(!summary || !participation || !devices || !registrations) {
            return result;
        }
        // Graphs Data // Employees
        var days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        var temp = [];

        var startDate = new Date(summary.startDate)
        var endDate = new Date(summary.endDate)

        var regBins = {};
        var engBins = {};
        var devBins = {};

        var labelsEmp = [];
        var labelsEng = [];
        var labelsDev = [];
        

        while(startDate.getTime() < endDate.getTime()) {
            //console.log(startDate)
            var date  = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), -startDate.getUTCHours());
            console.log(date)
            var label = days[startDate.getDay()] + ", " + months[startDate.getMonth()] + " " + startDate.getDate();
            labelsEmp.push(label);
            labelsEng.push(label);
            labelsDev.push(label);
            regBins[date.getTime()] = 0;
            devBins[date.getTime()] = 0;
            startDate.setDate(startDate.getDate()+1);
        }

        registrations.result.forEach(function (item) {
            var d = new Date(item.date);
            var point = item.count;
            if(isNaN(point))
                point = 0;
            regBins[d.getTime()] = point;
        });

        var regPoints = Object.values(regBins)

        regPoints[0] += registrations.previousTotal;
        for(var i = 1; i < regPoints.length; i++) {
            regPoints[i] = regPoints[i-1] + regPoints[i];
        }

        participation.result.forEach(function (item, index) {
            var d = new Date(item.date);
            var point = item.count

            engBins[d.getTime()] = point;
        });
        var engPoints = Object.values(engBins);

        // Devices //
        devices.result.forEach(function (item, index) {
            var d = new Date(item.date);
             var point = item.count;
             if(isNaN(point))
                point = 0;
            console.log(d)
            devBins[d.getTime()] = point;
        });
        console.log(devBins)
        var devPoints = Object.values(devBins)
        devPoints[0] += devices.previousTotal;
        for(var i = 1; i < devPoints.length; i++) {
            devPoints[i] = devPoints[i-1] + devPoints[i];
        }
        
        result.participation_data = [participation.count * 100 / registrations.total, 100 - (participation.count * 100 / registrations.total)];
        
        result.devices_data = [devices.total * 100 / registrations.total, 100 - (devices.total * 100 / registrations.total)];

        result.employees_data = [registrations.total * 100 / registrations.employees, 100 - (registrations.total * 100 / registrations.employees)];

        result.data = {
            labels: null,
            datasets: [
                {
                    data:null,
                    fill: false,
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    borderWidth: 2,
                    borderRadius: 5,
                    hoverBackgroundColor: Color(colors.themeBlue).alpha(0.50).string(),
                    hoverBorderColor: Color(colors.themeBlue).alpha(0.75).string(),
                    pointRadius: 2,
                }
            ],
        };

        if(selectedGraph === EmployeesGraph) {
            result.type = 'line'
            result.data.labels = labelsEmp;
            result.data.datasets[0].data = regPoints;
        } else if(selectedGraph === DevicesGraph) {
            result.type = 'line'
            result.data.labels = labelsDev;
            result.data.datasets[0].data = devPoints;
        } else { //EngagementGraph
            result.type = 'line'
            result.data.labels = participation.result.map(point => moment(point.date).format('ddd, MM, DD'))
            result.data.datasets[0].data = engPoints;
        }

        return result;
    }

    const getGraphPanes = () => {
        return [
            {
                menuItem: (
                <Menu.Item key={'Members'}>
                    <Popup
                        content='Culmulative members'
                        trigger={<div>{t('Members')}</div>}
                    />
                </Menu.Item>),
                pane: ''
            },
            {
                menuItem: (
                <Menu.Item key={'Wearable Integrations'}>
                    <Popup
                        content='Culmulative members with connected devices'
                        trigger={<div>{t('Wearable Integrations')}</div>}
                    />
                </Menu.Item>),
                pane: ''
            },
            {
                menuItem: (
                <Menu.Item key={'Engagement'}>
                    <Popup
                        content='Daily Active Users - users who performed an event in app'
                        trigger={<div>{t('Weekly Engagement')}</div>}
                    />
                </Menu.Item>),
                pane: ''
            }
        ];
    }

    const onChangeGraph = (event, {activeIndex}) => {
        setSelectedGraph(activeIndex)
    }

    const onExportCsv = (period) => {
        var link = downloadLink;

        function exportCallback() {

             return DatabaseService.getEngagement({period})
             .then(resp => {
                    return resp.text()}
             ).then(text => {
                if (navigator && navigator.msSaveBlob) { // IE 10+
                    var exportedFilenmae = link.download;
                    var blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var encodedUri = encodeURI("data:text/csv;charset=utf-8," +text);
                    link.setAttribute("href", encodedUri);
                    link.click(); 
                }
            },
            function (err) {
                console.log('Error exporting! ' + err)
            })
        }
        exportCallback(); 
    }

    return (
        <Dashboard busy={busy}
                    registrations={registrations}
                    participation={participation}
                    devices={devices}
                    teams={teams}
                    rewards={rewards}
                    users={users}
                    categories={categories}
                    selectedGraph={selectedGraph}
                    platformEngagement={getPlatformEngagement()}
                    platformChartOptions={platformChartOptions}
                    graphData={getGraphData()}
                    graphOptions={graphOptions}
                    graphPanes={getGraphPanes()}
                    onChangeGraph={onChangeGraph}
                    onExportCsv={onExportCsv}
                    setDownloadLink={downloadLink}
        />
    );
}
export default withRouter(connect()(withTranslation('dashboard')(DashboardContainer)));
